html {
	scroll-behavior: smooth;
}

.landing-page {
	padding: 25px 5%;
}

.chip {
	background-color: #fff8d3;
	color: #caa40d;
	border-radius: 5px;
	padding: 0px 10px;
	margin-right: auto;
	
}

.chip-bookings {
	background-color: #e1c4ff;
	color: #a857ff;
	border-radius: 5px;
	padding: 0px 10px;
	margin-right: auto;
	
}

.green {
	background-color: #daffee;
	color: #0f874f;
}

@media (min-width: 1600px) {
	.landing-page {
		padding: 25px 10%;
	}
}
